import React from 'react';
import { Navbar, Nav, NavDropdown, Dropdown, Form, FormControl, Button } from 'react-bootstrap';
// import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';


const Header = () => {
  return (
    


    <Nav variant="tabs" defaultActiveKey="/home">
      <Nav.Item>
        {/* <Nav.Link href="/home">Giriş</Nav.Link> */}
        <Nav.Link eventKey="link-2">Giriş</Nav.Link>
      </Nav.Item>
      
      <Nav.Item>
        <Nav.Link eventKey="disabled">
        Üye ol
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1">Etkınlık iste</Nav.Link>
      </Nav.Item>
      <Form inline>
            <FormControl type="text" placeholder="Etkınlık ara" className="mr-sm-2" />
          </Form>
          <NavDropdown title="Kategoride ara" id="basic-nav-dropdown">
            <Form>
              <FormControl type="text" placeholder="Ara" className="mr-sm-2" />
              <Form.Check type="checkbox" label="Item 1" />
              <Form.Check type="checkbox" label="Item 2" />
              <Form.Check type="checkbox" label="Item 3" />
            </Form>
          </NavDropdown>

    </Nav>
  );
}




  
export default Header;
