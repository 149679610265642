// import logo from '../../logo.svg';
import '../../App.css';

import '@coreui/coreui/dist/css/coreui.min.css';
import { CContainer,CRow,CCol  } from '@coreui/react';

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import Etkinlik from '../etkinlik-card';
import logo from '../../logo.svg';
import tarkan from '../../tarkan.jpg';
import rashid from '../../rashid.jpg';


const Home = ()=>{

    return(

        <div className='homebody'>

    <CContainer >
        <CRow>
        <h3 style={{color:'white'}}>Aktıf Etkınlıkler</h3>
        </CRow>

        <CRow xs={{cols:'auto'}}>
        <div className="row justify-content-center">
        <CCol><Etkinlik reactImage={tarkan}/></CCol>
        <CCol><Etkinlik reactImage={tarkan}/></CCol>
        {/* <CCol><Etkinlik reactImage={tarkan}/></CCol> */}
        
        {/* <CCol><Etkinlik reactImage={tarkan}/></CCol> */}
        {/* <CCol><Etkinlik reactImage={tarkan}/></CCol> */}
        
        </div>
        
        {/* <Etkinlik /> */}
        </CRow>

        

    </CContainer>
        

        </div>
    );

    
}

export default Home;