// import logo from '../logo.svg';
import './App.css';
import Header from './components/header';
import Home from './components/pages/home-page';

function App() {
  return (
    <div className="App">
      
      <header className="App-header">
        <Header />     
      </header>
      <Home/>
    </div>
  );
}

export default App;
