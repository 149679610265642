import '@coreui/coreui/dist/css/coreui.min.css';
import { CCard, CCardImage,CCardBody,CCardTitle,CCardText,CButton,CCardHeader,CContainer  } from '@coreui/react';

// import logo from '../logo.svg';
// import tarkan from '../tarkan.jpg';
import '../App.css';








const Etkinlik = ({reactImage})=> {

    return(
        <div>
                            
            <CCard style={{ width: '10rem' }}>
        <CCardHeader>Toplantı saatı:</CCardHeader>
            <CCardImage orientation="bottom" src={reactImage} />
            <CCardBody>
            <CCardTitle>TARKAN</CCardTitle>
            <CCardText>
            Tanınmış ünlü Tarkan ile heyecan verici bir buluşmada bize katılın!.
            </CCardText>
            <CButton href="#">Satın al</CButton>
            </CCardBody>
        </CCard>

        
          

        
        
        </div>
    );
}

export default Etkinlik;